type OperationWrapper = {
    data: any
    status?: OperationStatus;
    message?: string;
    aaOperation?: string;
    aaOpAttempt?: number
    aaErrorCode?: string
}
type OperationStatus = 'SUCCESS' | 'FAILURE' | 'BAD_REQUEST' | 'SESSION_ERROR' | 'NETWORK_ERROR' | 'RETRY' | 'ALREADY_PROCESSED' | 'UNKNOWN_ERROR' | 'RECORD_NOT_FOUND' | 'SESSION_EXPIRED' | 'FIP_UNAVAILABLE';
//SESSION_ERROR - restart the journey
export type OperationType = 'VALIDATE_USER' | 'AA_LOGIN' | 'AA_REGISTER' | 'ACCOUNT_DISCOVERY' | 'LINKED_ACCOUNTS' | 'ALT_MOBILE' | 'CONFIRM_ALT_MOBILE' | 'ACCOUNT_LINKING' | 'CONFIRM_ACCOUNT_LINKING' | 'CONSENT_ACTION' | 'USER_INFO' | 'LOGOUT' | 'CONSENT_DETAILS';
export class ResponseWrapper {
    getModifiedResponse(response: any, aaHandle: string, operation?: OperationType): OperationWrapper | undefined {
        const responseObj = {} as OperationWrapper;
        const { status, message, ...rest } = response;
        responseObj.data = rest;
        if (response.aaOperation) {
            responseObj.aaOperation = response.aaOperation;
        }
        if (response.attempt) {
            responseObj.aaOpAttempt = response.attempt;
        }
        if (response.errorCode) {
            responseObj.aaErrorCode = response.errorCode;
        }
        switch (operation) {
            case 'VALIDATE_USER':
                switch (aaHandle) {
                    case 'nadl':
                        if (response.statusCode === 200) {
                            responseObj.status = 'SUCCESS';
                        }
                        if (response.statusCode === 400) {

                            if (response.errorCode === 603) {
                                // bad request - we can't do anything
                                responseObj.status = 'BAD_REQUEST';
                                responseObj.message = 'Invalid VUA';
                            }
                            if (response.errorCode === 8024) {
                                // some weird error Cannot decrypt. Deployments in progress. Please check after some time
                                // switch AA 
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                            if (response.errorCode === 51) {
                                ///bad request - we can't do anything
                                responseObj.status = 'BAD_REQUEST';
                                responseObj.message = 'Invalid format of VUA';
                            }

                            if (response.errorCode === 7006 || response.errorCode === 8023) {
                                // some failure occurred , not in our hands
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'For security reasons, we have limited the number of times you can generate OTP. Please try after 5 minutes';
                            }

                            if(response.errorCode ===8000){
                                responseObj.status = 'SESSION_ERROR';
                                responseObj.message = 'Session Timed Out. Please restart the journey';
                            }

                        }
                        if (response.statusCode === 404) {
                            if (response.errorCode === 235) {
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'Some network error occured. Please try again later';
                            }
                        }

                        if(response.statusCode ===500){
                            if(response.errorCode===500){
                                responseObj.status = 'UNKNOWN_ERROR';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                        }
                        break;

                    case 'finvu':
                        if (response.status === 'SUCCESS' || response.status === 'SEND') {
                            responseObj.status = 'SUCCESS';
                        }
                        if (response.status === 'RETRY') {
                            responseObj.status = 'RETRY';
                        }

                        if (response.status === 'FAILURE') {
                            const message = response.message;
                            responseObj.message = response.message;
                            switch (message) {
                                case 'Bad Request':
                                    responseObj.status = 'BAD_REQUEST';
                                    /* case 'Something went wrong.':
                                        responseObj.status = 'FAILURE'; */
                                    break;
                                case 'Invalid request or request timed out.':
                                case 'Maximum OTP limit reached for session.':
                                    responseObj.status = 'FAILURE';
                                    break;

                                case 'Error retreiving consent request: Consent request not found':
                                case 'Consent request not in PENDING status':
                                    responseObj.status = 'FAILURE';
                                    break;
                               /*  default: {
                                    responseObj.status = 'UNKNOWN_ERROR';
                                    break;
                                } */
                            }
                            //return responseObj;
                        }

                        break;
                }
                break;
            case 'AA_LOGIN':
                switch (aaHandle) {
                    case 'nadl':
                        if (response.statusCode === 200) {
                            responseObj.status = 'SUCCESS';

                        }

                        if (response.statusCode === 400) {

                            if (response.errorCode === 608) {
                                //generally on nadl , this is bypassed , i.e further apis are still called after this error
                                responseObj.status = 'BAD_REQUEST';
                                responseObj.message = 'MobileNo validation Failed';
                            }
                            if (response.errorCode === 4000) {
                                //confrim: - Account has been blocked 
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                            if (response.errorCode === 227) {
                                responseObj.status = 'RETRY';
                                responseObj.message = 'Otp Verfication Failed';
                            }
                            if (response.errorCode === 608) {
                                responseObj.status = 'RECORD_NOT_FOUND';
                                responseObj.message = 'MobileNo validation Failed';
                            }
                            if(response.errorCode ===8000){
                                responseObj.status = 'SESSION_ERROR';
                                responseObj.message = 'Session Timed Out. Please restart the journey';
                            }
                            if (response.errorCode === 131) {

                                if (response?.response?.data?.errors) {
                                    const errors = response?.response?.data?.errors;
                                    errors.forEach((element: { code: number; }) => {
                                        if (element.code === 600) {
                                            responseObj.status = 'BAD_REQUEST';
                                            responseObj.message = 'An account exists with this number';

                                            return;
                                        }
                                        if (element.code === 604) {
                                            responseObj.status = 'BAD_REQUEST';
                                            responseObj.message = 'Invalid Mobile Number';

                                            return;
                                        }
                                        if (element.code === 690) {
                                            responseObj.status = 'BAD_REQUEST';
                                            responseObj.message = 'VUA already taken';

                                            return;
                                        }
                                    });

                                }
                            }
                            if (response.errorCode === 226) {
                                //confirm this
                                responseObj.status = 'BAD_REQUEST';
                                responseObj.message = 'Invalid Mobile validation Id';
                            }
                            if (response.errorCode === 7006 || response.errorCode === 8023) {
                                // some failure occurred , not in our hands
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'For security reasons, we have limited the number of times you can generate OTP. Please try after 5 minutes';
                            }
                            if(response.statusCode ===500){
                                if(response.errorCode===500){
                                    responseObj.status = 'UNKNOWN_ERROR';
                                    responseObj.message = 'Some error occurred, please try again after some time';
                                }
                            }
                        }

                        if(response.statusCode ===500){
                            if(response.errorCode===500){
                                responseObj.status = 'UNKNOWN_ERROR';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                        }

                        break;

                    case 'finvu':
                        if (response.status === 'SUCCESS' || response.status === 'SEND') {
                            responseObj.status = 'SUCCESS';
                        }
                        if (response.status === 'FAILURE') {
                            const message = response.message;
                            responseObj.message = response.message;
                            switch (message) {
                                case 'Otp validation failed, try again':
                                case 'Otp validation failed.':
                                case 'otp reference and/or otp not set or has invalid values':
                                    responseObj.status = 'RETRY';
                                    break;
                                case 'Maximum OTP limit reached for session.':
                                    responseObj.status = 'FAILURE';
                                    break;    
                            }
                        }
                }
                break;
            case 'ACCOUNT_DISCOVERY':
                switch (aaHandle) {
                    case 'nadl':
                        if (response.statusCode === 201 || response.statusCode === 200) {
                            responseObj.status = 'SUCCESS';
                        }

                        if (response.statusCode === 400) {

                            if (response.errorCode === 2883 || response.errorCode ===9055) {
                                //esponseObj.status = 'RECORD_NOT_FOUND';
                                responseObj.status = 'RETRY';
                                responseObj.message = 'Account Discovery Failed';
                            }
                            if (response.errorCode === 2458) {
                                responseObj.status = 'BAD_REQUEST';
                                responseObj.message = 'Invalid mobile identifier value';
                            }

                            if(response.errorCode ===8000){
                                responseObj.status = 'SESSION_ERROR';
                                responseObj.message = 'Session Timed Out. Please restart the journey';
                            }
                        }

                        if(response.statusCode ===401 || response.statusCode ===403){
                            if(response.errorCode ===401 || response.errorCode ===5000){
                                responseObj.status = 'SESSION_ERROR';
                                responseObj.message = 'Session Timed Out. Please restart the journey';
                            }
                        }

                        if(response.statusCode ===500){
                            if(response.errorCode===500){
                                responseObj.status = 'UNKNOWN_ERROR';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                            if (response.errorCode === 2883 || response.errorCode ===9055) {
                                //esponseObj.status = 'RECORD_NOT_FOUND';
                                responseObj.status = 'RETRY';
                                responseObj.message = 'Account Discovery Failed';
                            }
                        }
                        if(response.statusCode ===404){
                            if(response.errorCode===2331 || response.errorCode===3002){
                                responseObj.status = 'BAD_REQUEST';
                                responseObj.message = 'Invalid Request';
                            }
                            if(response.errorCode===2887){
                                responseObj.status = 'RETRY';
                                responseObj.message = 'Account Discovery Failed';
                            }
                            if(response.errorCode==='FIP_UNAVAILABLE'){
                                responseObj.status = 'FIP_UNAVAILABLE';
                                responseObj.message = 'Fip is unavailable';
                            }
                        }
                        break;

                    case 'finvu':
                        if (response.status === 'SUCCESS') {
                            responseObj.status = 'SUCCESS';
                        }

                        if (response.status === 'RECORD_NOT_FOUND' || response.status === 'RECORD-NOT-FOUND') {
                            responseObj.status = 'RETRY';
                        }

                        if (response.status === 'FAILURE') {
                            const message = response.message;
                            switch (message) {
                                case 'Bad Request':
                                    responseObj.status = 'BAD_REQUEST';
                                    break;
                                 case 'Something went wrong.':
                                     responseObj.status = 'FAILURE';
                                     break;
                                case 'Invalid request or request timed out.':
                                case 'Session Error.':    
                                    responseObj.status = 'SESSION_ERROR';
                                    break;

                                default: {
                                    responseObj.status = 'FAILURE';
                                }
                            }

                        }
                        break;
                }
                break;

            case 'LINKED_ACCOUNTS':
                switch (aaHandle) {
                    case 'nadl':
                        if (response.statusCode === 200) {
                            responseObj.status = 'SUCCESS';
                        }
                        if(response.statusCode ===400){
                            if(response.errorCode ===8000){
                                responseObj.status = 'SESSION_ERROR';
                                responseObj.message = 'Session Timed Out. Please restart the journey';
                            }
                        }
                         if(response.statusCode ===500){
                            if(response.errorCode===500){
                                responseObj.status = 'UNKNOWN_ERROR';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                        }
                        break;

                    case 'finvu':
                        if (response.status === 'SUCCESS') {
                            responseObj.status = 'SUCCESS';
                        }
                        if (response.status === 'FAILURE') {
                            responseObj.status = 'FAILURE';
                        }
                        break;
                }
                break;


            case 'ALT_MOBILE':
                switch (aaHandle) {
                    case 'nadl':
                        if (response.statusCode === 200) {
                            responseObj.status = 'SUCCESS';
                        }

                        if (response.statusCode === 400) {


                            if (response.errorCode === 236) {
                                //confirm this - retriable error 
                                //responseObj.status = 'FAILURE';
                                responseObj.status = 'RETRY';
                                responseObj.message = 'Failed to send notification';//retry after 2-3 sec total - 10sec - failure 
                            }
                            if (response.errorCode === 234) {
                                responseObj.status = 'BAD_REQUEST';
                                responseObj.message = 'Invalid mobile number';
                            }
                            if(response.errorCode ===8000){
                                responseObj.status = 'SESSION_ERROR';
                                responseObj.message = 'Session Timed Out. Please restart the journey';
                            }

                        }
                        if(response.statusCode ===500){
                            if(response.errorCode===500){
                                responseObj.status = 'UNKNOWN_ERROR';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                        }

                        break;

                    case 'finvu':
                        if (response.status === 'ACCEPT' || response.status === 'SEND') {
                            responseObj.status = 'SUCCESS';
                        }

                        if (response.status === 'RETRY' || response.status === 'REJECT') {
                            responseObj.status = 'RETRY';
                        }

                        if (response.status === 'FAILURE') {
                            responseObj.status = 'FAILURE';
                            const message = response.message;
                            switch(message){
                                case 'Session Error.':
                                    responseObj.status = 'SESSION_ERROR';
                                    break; 
                            }
                            
                        }
                        break;
                }
                break;

            case 'CONFIRM_ALT_MOBILE':
                switch (aaHandle) {
                    case 'nadl':
                        if (response.statusCode === 200) {
                            responseObj.status = 'SUCCESS';
                        }

                        if (response.statusCode === 400) {

                            if (response.errorCode === 226) {
                                //confirm this
                                responseObj.status = 'BAD_REQUEST';
                                responseObj.message = 'Invalid Mobile validation Id';
                            }
                            if (response.errorCode === 227) {
                                responseObj.status = 'RETRY';
                                responseObj.message = 'Otp Verfication Failed';
                            }
                            if(response.errorCode ===8000){
                                responseObj.status = 'SESSION_ERROR';
                                responseObj.message = 'Session Timed Out. Please restart the journey';
                            }

                        }
                        if(response.statusCode ===500){
                            if(response.errorCode===500){
                                responseObj.status = 'UNKNOWN_ERROR';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                        }

                        break;

                    case 'finvu':
                        if (response.status === 'ACCEPT' || response.status === 'SEND') {
                            responseObj.status = 'SUCCESS';
                        }

                        if (response.status === 'RETRY' || response.status === 'REJECT') {
                            responseObj.status = 'RETRY';
                        }

                        if (response.status === 'FAILURE') {
                            responseObj.status = 'FAILURE';
                            const message = response.message;
                            switch(message){
                                case 'Session Error.':
                                    responseObj.status = 'SESSION_ERROR';
                                    break; 
                            }
                            
                        }
                        break;

                }
                break;

            case 'ACCOUNT_LINKING':
                switch (aaHandle) {
                    case 'nadl':
                        if (response.statusCode === 200 || response.statusCode === 201) {
                            responseObj.status = 'SUCCESS';
                        }

                        if (response.statusCode === 400) {

                            if (response.errorCode === 2330) {
                                //Failed to get fip server response , confirm this FIP server error : TODO : please check
                                //responseObj.status = 'UNKNOWN_ERROR';
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                            if (response.errorCode === 2884) {
                                // account link - confirm this TODO: retry 3 
                                responseObj.status = 'BAD_REQUEST';
                                responseObj.message = 'Failed to link account';
                            }
                            if (response.errorCode === 2339) {
                                // account link - confirm this - : TODO : handle later on!!
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'Account already linked';
                            }

                            if(response.errorCode ===8000){
                                responseObj.status = 'SESSION_ERROR';
                                responseObj.message = 'Session Timed Out. Please restart the journey';
                            }
                            

                        }
                        if(response.statusCode ===500){
                            if(response.errorCode===500){
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                        }
                        if(response.statusCode ===401 || response.statusCode ===403 || response.statusCode ===500){
                            if(response.errorCode ===401 || response.errorCode ===5000){
                                responseObj.status = 'SESSION_ERROR';
                                responseObj.message = 'Session Timed Out. Please restart the journey';
                            }
                        }

                        break;

                    case 'finvu':
                        if (response.status === 'SUCCESS') {
                            responseObj.status = 'SUCCESS';
                        }

                        if (response.status === 'RETRY' || response.status === 'REJECT') {
                            responseObj.status = 'RETRY';
                        }

                        if (response.status === 'FAILURE') {
                            const message = response.message;
                            switch (message) {
                                case 'Bad Request':
                                    responseObj.status = 'BAD_REQUEST';
                                    break;
                                case 'Session Error.':
                                    responseObj.status = 'SESSION_ERROR';
                                    break;     
                                //case 'Something went wrong.':
                                //    responseObj.status = 'BAD_REQUEST';
                                //    break;
                                /*  case 'Otp validation failed, try again':
                                 case 'Otp validation failed.':
                                     responseObj.status = 'RETRY';
                                     break;
 
                                 default: {
                                     responseObj.status = 'RETRY';
                                     break;
                                 } */
                            }
                        }

                        if (response.status === 'RECORD_NOT_FOUND') {
                            responseObj.status = 'FAILURE';
                        }
                        break;



                }
                break;

            case 'CONFIRM_ACCOUNT_LINKING':
                switch (aaHandle) {
                    case 'nadl':
                        if (response.statusCode === 200 || response.statusCode === 201) {
                            responseObj.status = 'SUCCESS';
                        }

                        if (response.statusCode === 400) {
                            if (response.errorCode === 2330) {
                                //Failed to get fip server response FIP server error
                                //responseObj.status = 'UNKNOWN_ERROR';
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                            if (response.errorCode === 9050) {
                                //Failed to get fip server response // TODO : retry the process
                                responseObj.status = 'RETRY';
                                responseObj.message = 'The asset linking process could not completed due to incorrect authentication credentials. Pls re-try again with correct details';
                            }
                            if (response.errorCode === 2335) {
                                responseObj.status = 'BAD_REQUEST';
                                responseObj.message = 'Invalid Account link ref number';
                            }

                            if(response.errorCode ===8000){
                                responseObj.status = 'SESSION_ERROR';
                                responseObj.message = 'Session Timed Out. Please restart the journey';
                            }

                        }
                        if(response.statusCode ===500){
                            if(response.errorCode===500){
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                        }
                        break;

                    case 'finvu':
                        if (response.status === 'SUCCESS') {
                            responseObj.status = 'SUCCESS';
                        }

                        if (response.status === 'RETRY' || response.status === 'REJECT') {
                            responseObj.status = 'RETRY';
                        }

                        if (response.status === 'FAILURE') {
                            const message = response.message;
                            switch (message) {
                                case 'Bad Request':
                                    responseObj.status = 'BAD_REQUEST';
                                    break;
                                case 'Token incorrect.':
                                    responseObj.status = 'RETRY';  
                                    break;  
                                //case 'Something went wrong.':
                                //    responseObj.status = 'BAD_REQUEST';
                                //    break;
                                 case 'Otp validation failed, try again':
                                 case 'Otp validation failed.':
                                     responseObj.status = 'RETRY';
                                     break;
                                 case 'Session Error.':
                                    responseObj.status = 'SESSION_ERROR';
                                    break;   
 
                                 default: {
                                     responseObj.status = 'RETRY';
                                     break;
                                 }
                            }
                        }

                        if (response.status === 'RECORD_NOT_FOUND') {
                            responseObj.status = 'FAILURE';
                        }
                        break;

                }
                break;

            case 'CONSENT_ACTION':
                switch (aaHandle) {
                    case 'nadl':
                        if (response.statusCode === 200 || response.statusCode === 201) {
                            responseObj.status = 'SUCCESS';
                        }

                        if (response.statusCode === 400) {
                            if (response.errorCode === 182) {
                                //Failed to get fip server response Consent creation request failed
                                responseObj.status = 'FAILURE';
                                //responseObj.message = 'Consent creation request failed';
                                responseObj.message = 'Some error occurred, please try again after some time'
                            }
                            if (response.errorCode === 185) {
                                responseObj.status = 'BAD_REQUEST';
                                responseObj.message = 'Invalid Consent accounts';
                            }
                            if (response.errorCode === 201) {
                                responseObj.status = 'BAD_REQUEST';
                                responseObj.message = 'Consent Enddate is already over';
                            }
                            if (response.errorCode === 5002) {
                                responseObj.status = 'BAD_REQUEST';
                                responseObj.message = 'Access Token should not be null';
                            }
                            if (response.errorCode === 141) {
                                //Error While Sending Consent Artefact to FIP
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                            if (response.errorCode === 100) {
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'Server error';
                            }
                            if (response.errorCode === 194) {
                                //Failed to get fip server response TODO : retry 3 
                                //responseObj.status = 'FAILURE';
                                responseObj.status = 'RETRY';
                                responseObj.message = 'Consent rejection failed';
                            }

                            if(response.errorCode ===8000){
                                responseObj.status = 'SESSION_ERROR';
                                responseObj.message = 'Session Timed Out. Please restart the journey';
                            }

                        }
                        if(response.statusCode ===500){
                            if(response.errorCode===500){
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                        }
                        if(response.statusCode ===401 || response.statusCode ===403){
                            if(response.errorCode ===401 || response.errorCode ===5000){
                                responseObj.status = 'SESSION_ERROR';
                                responseObj.message = 'Session Timed Out. Please restart the journey';
                            }
                        }
                        break;


                    case 'finvu':
                        if (response.status === 'SUCCESS') {
                            responseObj.status = 'SUCCESS';
                        }
                        if (response.status === 'RECORD-NOT-FOUND') {
                            responseObj.status = 'BAD_REQUEST';
                        }

                        if (response.status === 'FAILURE') {
                            const message = response.message;
                            responseObj.message = response.message;
                            switch (message) {
                                case 'Bad Request':
                                    responseObj.status = 'BAD_REQUEST';
                                    break;
                                //case 'Something went wrong.':
                                case 'Invalid linked account(s) selected.':
                                    responseObj.status = 'BAD_REQUEST';
                                    break;
                                case 'Session Error.':
                                    responseObj.status = 'SESSION_ERROR';
                                    break;       
                                default: {
                                    responseObj.status = 'UNKNOWN_ERROR';
                                    break;
                                }
                            }
                        }
                        break;
                }
                break;

            case 'USER_INFO':
                switch (aaHandle) {
                    case 'nadl':
                        if (response.statusCode === 200) {
                            responseObj.status = 'SUCCESS';

                        }
                        if(response.statusCode ===500){
                            if(response.errorCode===500){
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                        }

                        if (response.statusCode === 400) {

                            if (response.errorCode === 522) {
                                responseObj.status = 'BAD_REQUEST';
                                responseObj.message = 'Invalid Customer Id';
                            }

                            if(response.errorCode ===8000){
                                responseObj.status = 'SESSION_ERROR';
                                responseObj.message = 'Session Timed Out. Please restart the journey';
                            }

                        }
                        break;

                    case 'finvu':
                        if (response.status === 'ACCEPT') {
                            responseObj.status = 'SUCCESS';
                        }
                        break;
                }
                break;

            case 'LOGOUT':
                switch (aaHandle) {
                    case 'nadl':
                        if (response.statusCode === 204 || response.statusCode === 200) {
                            responseObj.status = 'SUCCESS';
                        }

                        break;

                    case 'finvu':
                        if (response.status === 'SUCCESS') {
                            responseObj.status = 'SUCCESS';
                        }
                        break;
                }
                break;
            case 'CONSENT_DETAILS':
                switch (aaHandle) {
                    case 'nadl':
                        if (response.statusCode === 200) {
                            responseObj.status = 'SUCCESS';
                        }

                        if(response.statusCode===400){
                            if(response.errorCode ===8000){
                                responseObj.status = 'SESSION_ERROR';
                                responseObj.message = 'Session Timed Out. Please restart the journey';
                            }
                        }
                        if(response.statusCode ===500){
                            if(response.errorCode===500){
                                responseObj.status = 'FAILURE';
                                responseObj.message = 'Some error occurred, please try again after some time';
                            }
                        }

                        break;

                    case 'finvu':
                        if (response.status === 'SUCCESS') {
                            responseObj.status = 'SUCCESS';
                        }
                        if (response.status === 'FAILURE') {
                            responseObj.status = 'FAILURE';
                        }
                        break;

                }
        }
        return responseObj;
    }

}