export const enum ConductorEvent {
  OPEN = "OPEN",
  ERROR = "ERROR",
  EXIT = "EXIT",
  
  AA_SELECTED = "AA_SELECTED",
  FIP_SELECTED = "FIP_SELECTED",
  PRE_AA_OTP_SENT="PRE_AA_OTP_SENT",
  AA_OTP_SENT = "AA_OTP_SENT",
  POST_AA_OTP_SENT = "POST_AA_OTP_SENT",
  PRE_AA_OTP_RESENT="PRE_AA_OTP_RESENT",
  AA_OTP_RESENT = "AA_OTP_RESENT",
  POST_AA_OTP_RESENT="POST_AA_OTP_RESENT",
  AA_OTP_ENTERED="AA_OTP_ENTERED",
  AA_OTP_DETECTED="AA_OTP_DETECTED",
  UNAVAILABLE_FIP_SELECTED = "UNAVAILABLE_FIP_SELECTED",
  FIP_SEARCH = "FIP_SEARCH",

  // AUTO Discovery Mode
  AUTO_DISCOVERY_MODE = "AUTO_DISCOVERY_MODE",
  /**
   * Failed to Send AA OTP.
   */
  PRE_AA_OTP_VERIFY="PRE_AA_OTP_VERIFY",
  POST_AA_OTP_VERIFY="POST_AA_OTP_VERIFY",
  AA_OTP_FAILED = "AA_OTP_FAILED",
  AA_OTP_VALID = "AA_OTP_VALID",
  AA_OTP_INVALID = "AA_OTP_INVALID",
  /**
   * Failed to verify the OTP due to an interaction error with the AA service.
   */
  AA_OTP_VERIFY_FAILED = "AA_OTP_VERIFY_FAILED",
  ALT_MOBILE_AA_OTP_SENT = "ALT_MOBILE_AA_OTP_SENT",
  ALT_MOBILE_AA_OTP_RETRY = "ALT_MOBILE_AA_OTP_RETRY",
  ALT_MOBILE_AA_OTP_RESENT = "ALT_MOBILE_AA_OTP_RESENT",
  ALT_MOBILE_AA_OTP_FAILED = "ALT_MOBILE_AA_OTP_FAILED",
  ALT_MOBILE_AA_OTP_VALID = "ALT_MOBILE_AA_OTP_VALID",
  ALT_MOBILE_AA_OTP_INVALID = "ALT_MOBILE_AA_OTP_INVALID",
  /**
   * Failed to verify the Alt mobile OTP due to an interaction error with the AA service.
   */
  ALT_MOBILE_AA_OTP_VERIFY_FAILED = "ALT_MOBILE_AA_OTP_VERIFY_FAILED",
  ACCOUNTS_DISCOVERED = "ACCOUNTS_DISCOVERED",
  ACCOUNTS_DISCOVERY_FAILED = "ACCOUNTS_DISCOVERY_FAILED",
  LINKED_ACCOUNTS_FETCHED = "LINKED_ACCOUNTS_FETCHED",
  NO_LINKED_ACCOUNTS = "NO_LINKED_ACCOUNTS",
  ACCOUNTS_LINKED = "ACCOUNTS_LINKED",
  ACCOUNTS_LINKING_SKIPPED = "ACCOUNTS_LINKING_SKIPPED",
  ACCOUNTS_LINKING_FAILED = "ACCOUNTS_LINKING_FAILED",
  ACCOUNTS_LINKING_RETRY = "ACCOUNTS_LINKING_RETRY",
  ACCOUNTS_LINKING_OTP_SENT = "ACCOUNTS_LINKING_OTP_SENT",
  ACCOUNTS_LINKING_OTP_RESENT = "ACCOUNTS_LINKING_OTP_RESENT",
  ACCOUNTS_LINKING_OTP_VALID = "ACCOUNTS_LINKING_OTP_VALID",
  ACCOUNTS_LINKING_OTP_INVALID = "ACCOUNTS_LINKING_OTP_INVALID",
  ACCOUNTS_LINKING_OTP_FAILED = "ACCOUNTS_LINKING_OTP_FAILED",

  CONSENT_GRANTED = "CONSENT_GRANTED",
  CONSENT_DENIED = "CONSENT_DENIED",
  CONSENT_FAILED = "CONSENT_FAILED",
  CONSENT_PENDING = "CONSENT_PENDING",

  AA_ERROR = "AA_ERROR",
  API_ERROR= "API_ERROR",

  // Screen view related
  TRANSITION_VIEW = "TRANSITION_VIEW",
  SESSION_TIMED_OUT = "SESSION_TIMED_OUT",

  // Journey metric related
  JOURNEY_METRIC = "JOURNEY_METRIC",

  //exit survey feedback
  FEEDBACK = "FEEDBACK",
}

export const enum ErrorTypes {
  API_ERROR = "API_ERROR",
  NET_ERROR = "NET_ERROR",
  INSTITUTION_ERROR = "INSTITUTION_ERROR",
  FLOW_ERROR = "FLOW_ERROR",
  INPUT_ERROR = "INPUT_ERROR",
}

export const enum ErrorCodes {
  UNKNOWN = "UNKNOWN",
  NO_NETWORK = "NO_NETWORK",
  DATA_INVALID = "DATA_INVALID",
  ALTERNATIVE_CHOSEN = "ALTERNATIVE_CHOSEN",
  BAD_REQUEST = "BAD_REQUEST",
  INVALID_CONFIG = "INVALID_CONFIG",
  INVALID_INSTITUTION = "INVALID_INSTITUTION",
  FAILURE = "FAILURE",
  ALREADY_COMPLETED = "ALREADY_COMPLETED",
  LINK_EXPIRED="LINK_EXPIRED"
}

export const enum LastStatus {
  REQUIRES_INIT = "requires_init",
  REQUIRES_FIP_SELECTION = "requires_fip_selection",
  REQUIRES_AA_SELECTION = "requires_aa_selection",
  REQUIRES_AA_OTP = "requires_aa_otp",
  REQUIRES_LINKED_ACCOUNTS = "requires_linked_accounts",
  REQUIRES_ACCOUNTS_DISCOVERY = "requires_accounts_discovery",
  REQUIRES_ACCOUNTS_SELECTION = "requires_accounts_selection",
  REQUIRES_ACCOUNT_LINKING = "requires_account_linking",
  REQUIRES_CONSENT_ACTION = "requires_consent_action",
  UNKNOWN = "unknown",
  REQUIRES_ALT_MOBILE_OTP = "requires_alt_mobile_otp",
  CONSENT_ACTION_TAKEN = "consent_action_taken",
}

export const enum ErrorMessages {
  OTP_VALIDATION_FAILED = "OTP Validation Failed.",
  INVALID_ACCOUNTS = "INVALID_ACCOUNTS_LINKED",
  SESSION_EXPIRED = "Session Expired",
  BAD_REQUEST = "Bad Request",
  SOME_UNKOWN_ERROR = "Some unknown error",
  MOBILE_SAME_AS_USERID = "Please try with another mobile number, this one is same as currently active mobile number",
  OTP_EXPIRED = "Otp already used or expired.",
  NO_INSTITUTION = "An institution must be provided",
  UNSUPPORTED_FIP = "UNSUPPORTED_FIP",
  JOURNEY_CANCELLED = "Journey Cancelled",
}

export const enum CAUSE {
  NO_DATA = "NO_DATA",
  DATA_INVALID = "DATA_INVALID",
  UNKNOWN = "UNKNOWN",
  FAILURE = "FAILURE",
  FIP_UNAVAILABLE = "FIP_UNAVAILABLE"
}
export const enum CAUSE_TYPE {
  FIP_ERROR = "FIP_ERROR",
  AA_ERROR = "AA_ERROR",
}

export const enum ConductorFeatures {
  // add journey features here.
  ACCOUNTS_AUTO_SELECT = "ui.aa.accounts.auto-select",
  AUTO_DISCOVERY_FIP_COUNT = "ui.aa.consent.auto.discovery.top_n",
  FIP_ALT_MOBILE = "ui.aa.fip.alt-mobile.enable",
  ACCOUNTS_AUTO_DISCOVERY_LIMIT = "ui.aa.accounts.discovery.auto.implicit-retries",
  ACCOUNTS_AUTO_DISCOVERY = "ui.aa.accounts.auto.discovery",
  CONSENT_AUTO_GRANT = "ui.aa.consent.auto.grant",
  MANUAL_AUTO_DISCOVERY_RETRY = "ui.aa.accounts.discovery.auto.manual",
  //NO_ACCOUNTS_SELECTED_MODAL="ui.aa.no-accounts-selected-modal",
  //SHOW_THANK_YOU="ui.aa.show.thankyou-page",
  //SHOW_CONSENT="ui.aa.show.consent-page",
  LAYOUT = "ui.aa.layout",
  SELECT_FIPS_VIEW_TYPE = "ui.aa.view.selectFips.viewType",
  ACCOUNTS_VIEW_TYPE = "ui.aa.view.accounts.viewType",
  CONSENT_VIEW_TYPE = "ui.aa.view.consent-page.viewType",
  THANK_YOU_VIEW_TYPE = "ui.aa.view.thank-you.viewType",
  LOGIN_VIEW_TYPE = "ui.aa.view.login.viewType",
  MISSING_ACCOUNT_VIEW_TYPE = "ui.aa.view.missing-account.viewType",
  ALT_MOBILE_VIEW_TYPE = "ui.aa.view.alt-mobile.viewType",
  TERMS_AND_CONDITIONS = "ui.aa.view.login.terms",
  AA_ALT_MOBILE = "ui.aa.alt-mobile.enable",
  ALT_MOBILE = "ui.aa.altMobile",
  AA_ACCOUNT_FILTER = "ui.aa.temp.filter.accType",
  ALLOW_REDIRECTION = "ui.aa.allowRedirection",
  ACCOUNTS_MAX_SELECTIONS = "ui.aa.view.accounts.maxselections",
  AA_JOURNEY_ERROR = "ui.aa.view.error.viewType",
  AUTO_MOVE_LINKING = "ui.aa.auto.move.linking",
  ACCOUNTS_FITYPE_GROUPING = "ui.aa.accounts.fitype.group",
  ACCOUNTS_CONSENT_VIEW_SELECTION_TYPE = "ui.aa.consent.accounts.selectiontype",
  ACCOUNTS_CONSENT_VIEW_DEFAULT_SELECTED = "ui.aa.consent.accounts.defaultSelected",
  ASK_USER_DETAILS = "ui.aa.userdetails",
  LAYOUT_TYPE = "ui.aa.layoutType",
  DEBUG_EVENTS = "ui.debug.events",
  DEBUG_EVENTS_FIRE_HEARTBEAT = "ui.debug.events.heartbeat",

  // TEMP: ConfigCat features
  DUMMY_ENCRYPTION = "ui.aa.journey.dummyEncryptionAllowed",
  SHOW_REDIRECT_BUTTON = "ui.aa.journey.redirectButton",
  REDIRECTION_MODE = "ui.aa.journey.redirectionMode",
  CUSTOM_AA_JOURNEY = "ui.aa.journey.custom",
  // design / theme /layout affecting features
  // FONT_FAMILY="ui.aa.fontFamily",
  // FONT_FAMILY_URL="ui.aa.fontFamilyURL",
  // FONT_COLOR="ui.aa.fontColor",
  // CTA_DESIGN="ui.aa.ctaDesign", // one button or two buttoned
  // PRIMARY_COLOR="ui.aa.primaryColor",
  // BODY_BACKGROUND_COLOR="ui.aa.bodyBackGroundColor",
  // SECONDARY_COLOR="ui.aa.secondaryColor",
  // LOADER_COLOR="ui.aa.loaderColor",
  // SELECTION_COLOR="ui.aa.selectionColor",
  // INPUT_DESIGN_ROUNDED="ui.aa.input.rounded",
  // INPUT_DESIGN_DISABLE="ui.aa.input.disable",
  // INPUT_DESIGN_ACTIVE="ui.aa.input.active",
  // OTP_INPUT="ui.aa.otpInputType",
  // OTP_INPUT_SPLIT_COUNT="ui.aa.otpSplitCount",

  COLOR_FONT = "ui.aa.color.font",
  COLOR_PRIMARY = "ui.aa.color.primary",
  COLOR_SECONDARY = "ui.aa.color.secondary",
  COLOR_LOADER = "ui.aa.color.loader",
  COLOR_SELECTION = "ui.aa.color.selection",

  COLOR_INPUT = "ui.aa.color.input",
  COLOR_INPUT_ACTIVE = "ui.aa.color.input.active",
  COLOR_INPUT_FOCUS = "ui.aa.color.input.focus",
  COLOR_INPUT_DISABLED = "ui.aa.color.input.disabled",

  FONT_FAMILY = "ui.aa.font.family",
  OTP_INPUT_TYPE = "ui.aa.otp.input.type",
  OTP_INPUT_SPLIT_COUNT = "ui.aa.otp.input.splitCount",

  ROUNDED_INPUT = "ui.aa.rounded.input",
  ROUNDED_BUTTON = "ui.aa.rounded.button",

  BACKGROUND_BODY = "ui.aa.background.body",
  BACKGROUND_PRIMARY = "ui.aa.background.primary",
  BACKGROUND_SECONDARY = "ui.aa.background.secondary",

  CUSTOM_BORDER = "ui.aa.color.border",
  STROKE_COLOR = "ui.aa.color.stroke",

  SELECTION_MODE = "ui.aa.selection.mode",
  GROUPING_MODE = "ui.aa.grouping.mode",
  MANUAL_UPLOAD_REDIRECTION = "ui.aa.manual.upload.redirection",
  MANUAL_UPLOAD_REDIRECTION_BUTTON = "ui.aa.manual.upload.redirection.button",
  DOC_UPLOAD = "ui.aa.document.upload",
  DOC_UPLOAD_SUCCESS_REDIRECTION_MILLISECONDS = "ui.aa.document.upload.success.redirection.milliseconds",
  EXIT_FEEDBACK = "ui.aa.exit.feedback",
}

export const enum InternalConductorEvent {
  NO_ACCOUNTS_SELECTED_MODAL = "NO_ACCOUNTS_SELECTED_MODAL",
  SHOW_THANK_YOU = "SHOW_THANK_YOU",
  NO_ACCOUNTS_FOUND = "NO_ACCOUNTS_FOUND",
  AUTO_DISCOVERY_FAILED = "AUTO_DISCOVERY_FAILED",
  ALT_AUTO_DISCOVERY_FAILED = "ALT_AUTO_DISCOVERY_FAILED",
  RETRY_AUTO_DISCOVERY = "RETRY_AUTO_DISCOVERY",
  INVALID_URL = "INVALID_URL",
  JOURNEY_COMPLETED = "JOURNEY_COMPLETED",
  JOURNEY_EXIT = "JOURNEY_EXIT",
  NETWORK_ERROR = "NETWORK_ERROR",
  SESSION_ERROR = "SESSION_ERROR",
  BAD_REQUEST = "BAD_REQUEST",
  FAILURES = "FAILURES",
  INTERNAL_ERROR = "INTERNAL_ERROR",
}

export const enum JourneyType {
  SINGLE_CONSENT = "SINGLE_CONSENT",
  AUTHORIZED_SINGLE_CONSENT = "AUTHORIZED_SINGLE_CONSENT",
  MULTI_CONSENT = "MULTI_CONSENT",
  AUTHORIZED_MULTI_CONSENT = "AUTHORIZED_MULTI_CONSENT",
  ANUBHAV_X = "ANUBHAV_X",
  SINGLE_CONSENT_WITH_PARAMS = "SINGLE_CONSENT_WITH_PARAMS",
}

export const FILE_UPLOAD_STATUS_CODES = {
  COMPLETE: "Complete",
  PENDING: "Pending",
  ERROR: "Error",
};
export const FILE_ERROR_STATEMENT = {
  PASSWORD_REQUIRED:
    "Oh! Seems like this document is password protected, please submit the password or skip to discard the document(s)",
  FILE_INVALID: "Incorrect format. Please upload a pdf",
  OTHER: "Something went wrong",
  ALREADY_EXISTS: "Document already exists. please upload a different document",
};

export const API_ERROR_RESPONSE_CODES = {
  ALREADY_COMPLETED: "AlreadyCompleted",
  IN_PROCESS: "InProcess",
  LINK_EXPIRED: "LinkExpired",
  INTERNAL_ERROR: "InternalError",
  INVALID_REQUEST: "InvalidRequest",
  DATA_NOT_FOUND: "NoDataFound",

  //operation specific
  CONSENT_JOURNEY_FAILED: "CONSENT_JOURNEY_FAILED",
};

export const knownTenantFipAaPrefs = new Map<string, Map<string, string>>();
export const naviPrefs = new Map<string, string>();
naviPrefs.set("sbi-fip-uat", "nadl");
knownTenantFipAaPrefs.set("navi", naviPrefs);
